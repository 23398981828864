module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"enableCustomId":true,"className":"anchor-toc","offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"encode":"balance","tokenize":"strict","threshold":0,"resolution":3,"depth":3,"limit":10,"languages":["en"],"type":"Mdx","fields":[{"name":"keywords","indexed":true,"resolver":"fields.keywords","attributes":{"encode":"advanced","tokenize":"full","resolution":9},"store":true},{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"advanced","tokenize":"full","resolution":9,"boost":9},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"advanced","tokenize":"full","resolution":9},"store":true},{"name":"url","indexed":true,"resolver":"fields.slug","store":true}]},
    },{
      plugin: require('../packages/gatsby-theme-styleguide/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mozaic.adeo.cloud","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
